import LogRocket from "logrocket";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Button from "./Components/Button/Button";
import Modal from "./Components/Modal/Modal";
import SnackBar from "./Components/SnackBar/SnackBar";
import {
  forceRefreshAccessToken,
  logout,
} from "./Redux/authentication/actions";
import Complaints from "./Screens/Complaints/Complaints";
import Login from "./Screens/Login/Login";
import RaiseComplaint from "./Screens/RaiseComplaint/RaiseComplaint";
import SelectedComplaint from "./Screens/SelectedComplaint/SelectedComplaint";
import { useAuthSubscriber } from "./Services/authentication";
import {
  useCallsListener,
  useLocationListener,
  useProfileListener,
} from "./Services/database";
import { useComplaints } from "./Services/useComplaint";
import { logRocketInit } from "./Utils/constants";

function App(props) {
  const navigate = useNavigate();
  const [status, setStatus] = useState(null);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [auth, setAuth] = useState({
    loggedIn: null,
    claims: null,
  });
  const [formData, setFormData] = useState({
    name: "",
    issue: [],
    pctNo: "",
    phoneNumber: "+91",
    callId: "",
    sourceType: "",
  });

  useAuthSubscriber((props) => {
    setAuth({ claims: props.claims, loggedIn: props.isAuth });
  }, auth.loggedIn);

  useProfileListener({
    uid: props.auth.data.uid,
    phoneNumber: props.auth.data.phoneNumber,
    isAuth: auth.loggedIn,
  });

  useLocationListener({
    uid: props.auth?.data?.uid,
    isAuth: auth.loggedIn,
  });

  useCallsListener({
    uid: props.auth?.data?.uid,
    phoneNumber: props.auth.data.phoneNumber,
    isAuth: auth.loggedIn,
  });

  const [getComplaintsNextPage, complaintsLoading] = useComplaints({
    filterData: props.complaints?.filter,
    isAuth: auth.loggedIn,
    locationData: props.locations.data,
    phoneNumber: props.auth?.data?.phoneNumber,
    searchKey: props.complaints?.searchKey,
    sortBy: props.complaints?.sortBy,
  });

  //for snack bar messages
  useEffect(() => {
    if (props.status.message) {
      setStatus(props.status.message);
    } else {
      setStatus(null);
    }
  }, [props.status]);

  useEffect(() => {
    if (props.profile?.data?.accessSyncStatus === false && auth.loggedIn) {
      props.forceRefreshAccessToken(true);
    }
    // eslint-disable-next-line
  }, [props.profile?.data, auth.loggedIn]);

  //for log rocket init
  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      window.location.hostname !== "localhost"
    ) {
      const release =
        typeof process.env.REACT_APP_BUILD_NUM === "string"
          ? { release: process.env.REACT_APP_BUILD_NUM }
          : {};
      if (logRocketInit.key) {
        LogRocket.init(logRocketInit.key, release);
      }
    }
  }, []);

  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={
            <PublicRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
            >
              <Login navigate={navigate} />
            </PublicRoute>
          }
        />

        <Route
          path="/complaints"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
            >
              <Complaints
                navigate={navigate}
                logoutClick={() => {
                  setShowLogoutModal(true);
                }}
                getNextSetOfComplaints={() => {
                  getComplaintsNextPage();
                }}
                paginationLoading={complaintsLoading.pagination}
              />
            </ProtectedRoute>
          }
        />

        <Route
          path="/linkComplaint"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
            >
              <Complaints
                navigate={navigate}
                logoutClick={() => {
                  setShowLogoutModal(true);
                }}
              />
            </ProtectedRoute>
          }
        />

        <Route
          path="/raiseComplaint"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
            >
              <RaiseComplaint
                setFormData={setFormData}
                formData={formData}
                navigate={navigate}
                logoutClick={() => {
                  setShowLogoutModal(true);
                }}
              />
            </ProtectedRoute>
          }
        />

        <Route
          path="/complaint/:complaintId"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
            >
              <SelectedComplaint
                navigate={navigate}
                formData={formData}
                logoutClick={() => {
                  setShowLogoutModal(true);
                }}
                clearFormData={() => {
                  setFormData({
                    name: "",
                    issue: [],
                    pctNo: "",
                    phoneNumber: "",
                    callId: "",
                  });
                }}
              />
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<Navigate to="/complaints" replace />} />
      </Routes>

      <Modal
        show={showLogoutModal}
        canIgnore={true}
        onClose={() => {
          setShowLogoutModal(!showLogoutModal);
        }}
        width="inherit-parent-width"
        position=" absolute-center-self-horizontally bottom-0 "
        maxWidth="max-width-630px"
        background="false"
        boxShadow="false"
        borderRadius="false"
        height="height-fit-to-content"
      >
        <section className="font-color-secondary font-family-gilroy-medium font-color-secondary text-align-center padding-larger background-white">
          <div className=" font-family-RHD-medium font-size-medium">LOGOUT</div>
          <div className=" padding-top-default">
            Are you sure to logout from the application?
          </div>

          <section className="display-flex padding-top-larger ">
            <Button
              boxShadow={false}
              type="submit"
              variant="danger"
              text="Yes"
              className={"border-1px-e5e5e5 margin-right-small"}
              onClick={() => {
                props.logout();
                setShowLogoutModal(false);
              }}
              data-cy={"confirm-logout-button"}
            />
            <Button
              boxShadow={false}
              text="No"
              variant={"secondary"}
              onClick={() => {
                setShowLogoutModal(false);
              }}
              className={
                "font-color-secondary border-1px-e5e5e5 margin-left-small"
              }
              data-cy={"cancel-logout-button"}
            />
          </section>
        </section>
      </Modal>
      <SnackBar
        message={status}
        status={props.status}
        type={props.status.code === null ? "success" : "error"}
      />
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    status: state.status,
    complaints: state.complaints,
    auth: state.auth,
    profile: state.profile,
    locations: state.locations,
  };
};

const mapDispatchToProps = function () {
  return {
    logout: () => logout(),
    forceRefreshAccessToken: (status) => forceRefreshAccessToken(status),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);

const ProtectedRoute = ({ uid, children, profileData }) => {
  if (uid === null && profileData === null) {
    return <Navigate to="/login" />;
  } else {
    return children;
  }
};

const PublicRoute = ({ uid, children, profileData }) => {
  if (uid === null && profileData === null) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};
