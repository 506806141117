import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { isValidArray, isValidObject } from "../../Services/validators";
import { connect } from "react-redux";
import Header from "../../Components/Header/Header";
import { dateAndTimeConverter } from "../../Utils/constants";
import Button from "../../Components/Button/Button";
import Modal from "../../Components/Modal/Modal";
import { updateLinkComplaint } from "../../Redux/complaints/action";
import { setErrorStatus } from "../../Redux/status/action";

function SelectedComplaint(props) {
  const { complaintId } = useParams();
  const [compliantData, setCompliantData] = useState(null);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  useEffect(() => {
    if (complaintId && isValidObject(props.complaints.data)) {
      setCompliantData(props.complaints.data?.[complaintId]);
    }
  }, [complaintId, props.complaints.data]);

  const data = [
    { title: "COMPLAINT", value: compliantData?.category },
    { title: "COMPLAINT ID", value: compliantData?.documentId },
    { title: "STATUS", value: compliantData?.status?.currentStatus },
    {
      title: "COMPLAINT REGISTERED BY",
      value: compliantData?.issuedBy?.userDetails?.name,
    },
    {
      title: "LOCATION",
      value: `${
        props.locations.data?.[
          compliantData?.locationId
        ]?.PCTNo?.toString().padStart(2, "0") || "N/A"
      } - ${props.locations.data?.[compliantData?.locationId]?.location}`,
    },
    {
      title: "PREVIOUS LINKED CONTACTS",
      value: (
        <div className="">
          {isValidArray(compliantData?.issuedBy?.linkedWith) ? (
            compliantData?.issuedBy?.linkedWith?.map((data, index) => (
              <div
                data-cy={`contact-${data?.userDetails?.phoneNumber}`}
                className="display-flex padding-top-default padding-bottom-default flex-justify-content-space-between flex-align-items-center"
                key={index}
              >
                <div>
                  <div className="padding-bottom-small">
                    {data?.userDetails?.phoneNumber}
                  </div>
                  <div className="font-size-small">
                    {data?.userDetails?.name || "NOT PROVIDED"}
                  </div>
                </div>
                <div
                  className="font-size-smaller"

                  // onClick={() => {
                  //   setShowRemoveModal(true);
                  // }}
                >
                  {/* <CloseIcon /> */}
                  {`${dateAndTimeConverter(data?.issuedAt, "cardDate")},
                      ${dateAndTimeConverter(data?.issuedAt, "Time")}`}
                </div>
              </div>
            ))
          ) : (
            <div>N/A</div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="inherit-parent-width inherit-parent-height flex-center-children-horizontally">
      <div className="max-width-767px inherit-parent-width inherit-parent-height">
        <Header
          backOnClick={() => {
            props.navigate("/compliant");
          }}
          title="Selected Complaint"
          logoutOnClick={props.logoutClick}
        />
        <div className="padding-larger parent-height-sub-64px">
          <div className="inherit-parent-height display-flex flex-direction-column flex-justify-content-space-between">
            <div
              style={
                props.formData.phoneNumber
                  ? {
                      height: "calc(100% - 60px)",
                    }
                  : {
                      height: "100%",
                    }
              }
            >
              {data.map((data, i) => (
                <>
                  <div
                    key={i}
                    className="font-size-smaller padding-bottom-default"
                  >
                    {data.title}
                  </div>
                  <div
                    style={
                      data.title === "PREVIOUS LINKED CONTACTS"
                        ? {
                            height: "calc(100% - 234px)",
                          }
                        : {}
                    }
                    className={`${
                      data.title === "PREVIOUS LINKED CONTACTS"
                        ? "overflow-x-scroll hide-scrollbar"
                        : "padding-bottom-large"
                    } font-size-medium text-capitalize `}
                  >
                    {typeof data.value === "string" &&
                    data.title !== "COMPLAINT ID"
                      ? data.value.toLowerCase()
                      : data.value}
                  </div>
                </>
              ))}
            </div>

            {props.formData.phoneNumber && (
              <Button
                text="Link complaint"
                data-cy="link-compliant-button"
                type="button"
                boxShadow={false}
                loading={props.complaints.loading}
                onClick={() => {
                  if (
                    !isValidObject(
                      compliantData?.issuedBy?.linkedWith?.find(
                        (el) =>
                          el.userDetails.phoneNumber ===
                          props.formData.phoneNumber
                      )
                    )
                  ) {
                    props.updateLinkComplaint(
                      compliantData?.documentId,
                      isValidArray(compliantData?.issuedBy?.linkedWith)
                        ? [
                            ...compliantData?.issuedBy?.linkedWith,
                            {
                              source: {
                                type: "Exotel Call",
                                callId: props.formData.callId,
                              },
                              issuedAt: +new Date(),
                              userDetails: {
                                phoneNumber: props.formData.phoneNumber,
                                name: props.formData.name,
                              },
                            },
                          ]
                        : [
                            {
                              source: {
                                type: "Exotel Call",
                                callId: props.formData.callId,
                              },
                              issuedAt: +new Date(),
                              userDetails: {
                                phoneNumber: props.formData.phoneNumber,
                                name: props.formData.name,
                              },
                            },
                          ]
                    );
                    props.clearFormData();
                  } else {
                    setErrorStatus({
                      code: "custom",
                      message: "Already linked with this compliant",
                    });
                  }
                }}
              />
            )}
          </div>
        </div>

        <Modal
          show={showRemoveModal}
          canIgnore={true}
          onClose={() => {
            setShowRemoveModal(!showRemoveModal);
          }}
          width="inherit-parent-width"
          position="position-fixed bottom-0 left-0 right-0"
          maxWidth="max-width-630px"
          background="false"
          boxShadow="false"
          borderRadius="false"
          height="height-fit-to-content"
        >
          <section className="font-color-secondary font-family-gilroy-medium font-color-secondary text-align-center padding-larger background-color-white">
            <div className="font-size-medium">REMOVE CONTACT</div>

            <div>
              {`Are you certain about removing ${"test"} from this complaint`}
            </div>

            <section className="display-flex padding-top-larger ">
              <Button
                boxShadow={false}
                type="submit"
                variant="danger"
                text="Remove"
                className="border-1px-e5e5e5 margin-right-small"
                onClick={() => {
                  props.logoutOnClick();
                }}
                data-cy="confirm-button"
              />
              <Button
                boxShadow={false}
                text="Cancel"
                variant="secondary"
                onClick={() => {
                  setShowRemoveModal(false);
                }}
                className="font-color-secondary border-1px-e5e5e5 margin-left-small"
                data-cy="cancel-button"
              />
            </section>
          </section>
        </Modal>
      </div>
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    complaints: state.complaints,
    locations: state.locations,
  };
};

const mapDispatchToProps = function () {
  return {
    updateLinkComplaint: (documentId, data) =>
      updateLinkComplaint(documentId, data),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectedComplaint);
