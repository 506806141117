import {
  collection,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { database } from "./firebase";
import { isValidArray, isValidObject } from "./validators";
import { getComplaint } from "./database";
import { setComplaints } from "../Redux/complaints/action";
import { setErrorStatus } from "../Redux/status/action";
import { complaintsQueryPath, isValidDate } from "../Utils/constants";

export function useComplaints(props) {
  const [complaintsListener, setComplaintsListener] = useState({
    listener: null,
  });
  const [complaintsLoading, setComplaintsLoading] = useState({
    pagination: false,
    search: false,
  });
  const [searchData, setSearchData] = useState(null);
  const complaintsData = useRef(null);
  const currentCursor = useRef(null);
  const filterQuery = useRef([]);
  const listeningFilterData = useRef([]);
  const dataLimit = 50;

  const getComplaintsNextPage = async (preLoadedLimit, latestComplaints) => {
    setComplaintsLoading((prev) => ({ ...prev, pagination: true }));
    if (!currentCursor?.current) {
      return setComplaintsLoading((prev) => ({ ...prev, pagination: false }));
    }

    let paginationQueryRef = query(
      collection(database, "complaints"),
      where("connectedPhoneNumbers", "array-contains", props.phoneNumber),
      orderBy("createdAt", props.sortBy || "desc"),
      startAfter(currentCursor?.current),
      limit(preLoadedLimit !== undefined ? preLoadedLimit : dataLimit)
    );

    listeningFilterData.current?.forEach((value, index) => {
      if ((index + 1) % 3 === 0 && index !== 0) {
        paginationQueryRef = query(
          paginationQueryRef,
          where(
            listeningFilterData.current[index - 2],
            listeningFilterData.current[index - 1],
            value
          )
        );
      }
    });

    const querySnapshot = await getDocs(paginationQueryRef);
    let complaints = {};
    querySnapshot.forEach((doc) => {
      complaints[doc.id] = {
        ...doc.data(),
        documentId: doc.id,
      };
    });

    if (latestComplaints) {
      complaintsData.current = { ...latestComplaints, ...complaints };
    } else {
      complaintsData.current = { ...complaintsData.current, ...complaints };
    }

    currentCursor.current = querySnapshot.docs[querySnapshot.docs.length - 1];
    setComplaintsLoading((prev) => ({ ...prev, pagination: false }));

    setComplaints(complaintsData.current, filterQuery.current);
  };
  // need to verify about search only opened complaints for employee
  const getSearchedComplaint = async (complaintId) => {
    setComplaintsLoading((prev) => ({ ...prev, search: true }));
    const existData = complaintsData.current?.[complaintId.toUpperCase()];
    if (isValidObject(existData)) {
      setSearchData(existData);
    } else {
      const searchComplaint = await getComplaint(complaintId);
      if (isValidObject(searchComplaint)) {
        setSearchData(searchComplaint);
      } else {
        setSearchData("empty");
      }
    }
    setComplaintsLoading((prev) => ({ ...prev, search: false }));
  };

  const subscribeToComplaints = (phoneNumber, sortBy) => {
    console.log("subscribeToComplaints called");
    if (phoneNumber) {
      let queryRef = query(
        collection(database, "complaints"),
        where("connectedPhoneNumbers", "array-contains", phoneNumber),
        where("status.currentStatus", "==", "OPEN"),
        orderBy("createdAt", sortBy || "desc"),
        limit(dataLimit)
      );
      filterQuery.current.forEach((value, index) => {
        if ((index + 1) % 3 === 0 && index !== 0) {
          queryRef = query(
            queryRef,
            where(
              filterQuery.current[index - 2],
              filterQuery.current[index - 1],
              value
            )
          );
        }
      });

      return onSnapshot(
        queryRef,
        (dataSnapshot) => {
          let complaints = {};
          dataSnapshot.forEach((doc) => {
            complaints[doc.id] = {
              ...doc.data(),
              documentId: doc.id,
            };
          });

          currentCursor.current =
            dataSnapshot.docs[dataSnapshot.docs.length - 1];

          if (
            complaintsData.current &&
            Object.values(complaintsData.current)?.length > dataLimit
          ) {
            const previousComplaintsTotal =
              Object.values(complaintsData.current)?.length - dataLimit;
            getComplaintsNextPage(previousComplaintsTotal, complaints);
          } else if (searchData) {
            if (isValidObject(searchData)) {
              complaintsData.current = null;
              complaintsData.current = {
                [props.searchKey]: {
                  ...searchData,
                  documentId: props.searchKey,
                },
              };
            } else {
              complaintsData.current = null;
              complaintsData.current = {};
            }
          } else {
            complaintsData.current = complaints;
          }
          setComplaints(complaintsData.current, filterQuery.current);
        },
        (error) => {
          console.error(error, "from complaints");
          setErrorStatus(error);
        }
      );
    }
  };

  useEffect(() => {
    if (props.searchKey?.length === 8) {
      getSearchedComplaint(props.searchKey);
    } else if (props.searchKey?.length < 8 && searchData !== null) {
      setSearchData(null);
    }
    // eslint-disable-next-line
  }, [props.searchKey]);

  useEffect(() => {
    if (props.isAuth && typeof complaintsListener.listener === "function") {
      setComplaintsListener({
        listener: subscribeToComplaints(props.phoneNumber, props.sortBy),
      });
    }
    // eslint-disable-next-line
  }, [props.sortBy, searchData]);

  useEffect(() => {
    let query = [];
    if (isValidObject(props.filterData)) {
      Object.keys(props.filterData).forEach((key) => {
        switch (key) {
          case "dateRange":
            const { from, to } = props.filterData[key];
            if (from && isValidDate(from) && to && isValidDate(to)) {
              query = [
                ...query,
                complaintsQueryPath[key],
                ">=",
                +new Date(`${props.filterData[key].from}:00:00:00`),
                complaintsQueryPath[key],
                "<=",
                +new Date(`${props.filterData[key].to}:23:59:59`),
              ];
            }
            break;

          case "PCTNo":
            if (props.filterData[key] && props.locationData) {
              let locationIds = [];

              props.filterData[key].forEach((PCTNo) => {
                const location = Object.values(props.locationData).find(
                  (data) => data.PCTNo === PCTNo
                );
                if (location?.documentId) {
                  locationIds.push(location?.documentId);
                }
              });

              if (isValidArray(locationIds)) {
                query = [...query, complaintsQueryPath[key], "in", locationIds];
              }
            }
            break;

          case "issueRaised":
            if (props.filterData[key].length > 0) {
              query = [
                ...query,
                complaintsQueryPath[key],
                "==",
                `+91${props.filterData[key]}`,
              ];
            }
            break;

          default:
            if (isValidArray(props.filterData[key])) {
              if (props.filterData[key]) {
                query = [
                  ...query,
                  complaintsQueryPath[key],
                  "in",
                  props.filterData[key],
                ];
              }
            }
            break;
        }
      });
    }

    filterQuery.current = query;

    if (
      typeof complaintsListener.listener === "function" &&
      props.isAuth &&
      JSON.stringify(listeningFilterData.current) !==
        JSON.stringify(filterQuery.current)
    ) {
      complaintsData.current = null;
      listeningFilterData.current = filterQuery.current;
      complaintsListener.listener();
      setComplaintsListener({
        listener: subscribeToComplaints(props.phoneNumber, props.sortBy),
      });
    }
    // eslint-disable-next-line
  }, [props.filterData, props.locationData]);

  useEffect(() => {
    if (props.isAuth && complaintsListener.listener === null) {
      setComplaintsListener({
        listener: subscribeToComplaints(props.phoneNumber, props.sortBy),
      });
    } else if (
      !props.isAuth &&
      typeof complaintsListener.listener === "function"
    ) {
      complaintsListener.listener();
      setComplaintsListener({
        listener: null,
      });
    }
    // eslint-disable-next-line
  }, [props.isAuth, props.phoneNumber]);
  return [getComplaintsNextPage, complaintsLoading];
}
