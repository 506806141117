export const Regex = {
  pinCode: /^[1-9][0-9]{5}$/gm,
  aadhaarNumber: /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
  phoneNumber: /^[6-9]\d{9}$/,
  healthId: /^(?=[a-zA-Z])([a-zA-Z0-9]){2,13}[a-zA-Z]$/i,
  // eslint-disable-next-line
  email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
};

export const api = {
  baseUrl:
    process.env.REACT_APP_STAGING === "development"
      ? "https://api.dev.gzero.byepo.com"
      : process.env.REACT_APP_STAGING === "UAT"
      ? "https://api.uat.gzero.byepo.com"
      : process.env.REACT_APP_STAGING === "production"
      ? "https://api.rsbpct.com"
      : "http://127.0.0.1:5000"
};

export const bucketNames = {
  defaultBucket:
    process.env.REACT_APP_STAGING === "development"
      ? "gs://pct-dev-380105.appspot.com"
      : process.env.REACT_APP_STAGING === "UAT"
      ? "gs://pct-uat.appspot.com"
      : process.env.REACT_APP_STAGING === "production"
      ? "gs://pct-prod.appspot.com"
      : "gs://pct-dev-380105.appspot.com"
};

export const logRocketInit = {
  key:
    process.env.REACT_APP_STAGING === "development"
      ? "byepo/gzero-support-dev"
      : process.env.REACT_APP_STAGING === "UAT"
      ? "byepo/gzero-support-uat"
      : process.env.REACT_APP_STAGING === "production"
      ? "byepo/gzero-support-prod"
      : ""
};

export function rippleEffect(event) {
  const parentElement = event.currentTarget;
  const containerElement = event.currentTarget.parentElement;
  const viewportOffset = parentElement.getBoundingClientRect();
  parentElement.classList.add("ripple-element");

  const circle = document.createElement("span");
  circle.classList.add("ripple-span");
  const diameter = Math.max(
    parentElement.clientWidth,
    parentElement.clientHeight
  );
  const radius = diameter / 2;

  circle.style.width = circle.style.height = `${diameter}px`;
  circle.style.left = `${
    event.clientX +
    containerElement.scrollLeft -
    parentElement.offsetLeft -
    radius
  }px`;
  circle.style.top = `${
    event.clientY + containerElement.scrollTop - viewportOffset.y - radius
  }px`;
  circle.classList.add("ripples");

  const ripple = parentElement.getElementsByClassName("ripples")[0];

  if (ripple) {
    ripple.remove();
  }
  //Add ripple element
  parentElement.appendChild(circle);
}

export function dateAndTimeConverter(timestamp, type) {
  if (!timestamp) return;

  if (type === "cardDate") {
    let dateObject = new Date(timestamp);
    const date =
      dateObject.toLocaleString("en-IN", { day: "numeric" }) +
      " " +
      dateObject.toLocaleString("en-IN", { month: "short" });

    return date;
  } else {
    if (type === "firstDate") {
      let dateObject = new Date(timestamp);
      const date = dateObject
        .toLocaleString("en-IN", { day: "numeric" })
        .toUpperCase();
      return date;
    }
    if (type === "secondDate") {
      let dateObject = new Date(timestamp);
      const date =
        dateObject.toLocaleString("en-IN", { day: "numeric" }).toUpperCase() +
        " " +
        dateObject.toLocaleString("en-IN", { month: "short" }).toUpperCase() +
        " " +
        dateObject.toLocaleString("en-IN", { year: "numeric" });
      return date;
    }
  }

  if (type === "Time") {
    let dateObject = new Date(timestamp);
    const date = dateObject
      .toLocaleString("en-IN", {
        hour: "numeric" && "2-digit",
        minute: "numeric" && "2-digit",
        hour12: true
      })
      .toUpperCase();

    // + " ";
    return date;
  }
}

export const locationRange = 150; //meters
export const movePerSecond = 5; //meters
export const liveLocationUpdateTime = 5; //seconds

export function calculateDistance(location1, location2) {
  const deg2rad = (deg) => deg * (Math.PI / 180);

  if (location1 && location2) {
    const R = 6371; // Radius of the earth in kilometers
    const dLat = deg2rad(location2.latitude - location1.latitude);
    const dLon = deg2rad(location2.longitude - location1.longitude);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(location1.latitude)) *
        Math.cos(deg2rad(location2.latitude)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = Math.round(R * c * 1000); // Distance in meters

    return distance;
  } else {
    return null;
  }
}

export const getPublicURL = (documentId) => {
  switch (process.env.REACT_APP_STAGING) {
    case "local":
      return `http://localhost:3002/${documentId}`;

    case "development":
      return `https://public.dev.gzero.byepo.com/${documentId}`;

    case "production":
      return `https://public.rsbpct.com/${documentId}`;

    case "UAT":
      return `https://public.uat.gzero.byepo.com/${documentId}`;

    default:
      return `https://public.dev.gzero.byepo.com/${documentId}`;
  }
};

export const complaintsCategory = [
  "Restroom is not open",
  "No water supply",
  "Lights not working",
  "Waterlogged in the drainage",
  "Broken doors, latches",
  "Lack of security measures",
  "Janitors are not on duty",
  "Unsanitary restroom",
  "Fees are charged",
  "Unhygienic environment"
];

export const complaintsQueryPath = {
  status: "status.currentStatus",
  issueRaised: "issuedBy.userDetails.phoneNumber",
  issueTakenByPhoneNumber: "takenOverBy.phoneNumber",
  issueTakenByEmail: "takenOverBy.email",
  category: "category",
  ward: "location.ward",
  zone: "location.zone",
  dateRange: "createdAt",
  PCTNo: "location.id"
};

export function isValidDate(dateString) {
  const regex = /^[1-9]\d{3}-\d{2}-\d{2}$/;
  if (!regex.test(dateString)) {
    return false;
  }

  const [year, month, day] = dateString.split("-").map(Number);
  const date = new Date(year, month - 1, day);

  return (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day
  );
}
